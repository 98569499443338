import React from 'react';
import styles from "../../bio.module.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby"
import { isNullUndefined, addDisclaimersToCmsLinks, richTextOptions } from "../../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"

class Bio extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }
    render() { 
        const {topExcerptText, topExcerptButtonUrl, topExcerptImage} = this.props.queryData || {};

        let topExcerptTextRichText = documentToReactComponents(isNullUndefined(topExcerptText) ? topExcerptText : topExcerptText.json, richTextOptions);
 
        let topExcerptTextHtml = ReactDOMServer.renderToStaticMarkup(topExcerptTextRichText);
        return ( 
            <div>
                <div className="container">
                    <div className={`row ${styles.bio_content} `}>
                        <div className="col-12 col-sm-12 col-md-5">
                        <ScrollAnimation animateIn="fadeIn">
                                <img src={isNullUndefined(topExcerptImage) ? "": topExcerptImage.file.url} alt="bio image"/>
                        </ScrollAnimation>
                        </div>
                        <div className="col-12 col-sm-12 col-md-7">
                        <ScrollAnimation animateIn="fadeIn" delay={300}>
                        {ReactHtmlParser(topExcerptTextHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})}
                      <Link to={topExcerptButtonUrl}>
<button className={ `${styles.custom_btn } ${styles.bordered_btn}`}>
                                    <span>View more</span>
                            </button>
</Link>
                        </ScrollAnimation>
                              
                      
                               
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Bio;