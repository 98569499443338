import React from 'react';
import {graphql} from "gatsby"
import SiteVersion from '../../../components/changeSites';
import NonukResidentsBanner from '../../../components/UkResidents/NonHCP/NonukResidentsBanner';
import Bio from '../../../components/UkResidents/NonHCP/bio';
import Header from '../../../components/UkResidents/NonHCP/header';
import Vision from '../../../components/UkResidents/NonHCP/ourVision';
import UkNonHCPFooter from '../../../components/UkResidents/NonHCP/UkNonHCPFooter';
import queryString from 'query-string';
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryHomeTemplateUkNonHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulHomeTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                spaceId
                slug
                bannerSection {
                    json
                }
                missionSection {
                    json
                }
                topExcerptText {
                    json
                }
                topExcerptButtonUrl
                topExcerptImage {
                    file {
                      url
                    }
                }
                bottomExcerptText {
                    json
                }
                bottomExcerptButtonUrl
                bottomExcerptImage {
                  file {
                    url
                  }
                }
        }
    }
`

const HomeTemplatePageUkNonHcp = (props) => {
  const queryData = props.data.contentfulHomeTemplate;

  let [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {
    

      let {veeva} = queryString.parse(props.location.search || "");

      if(!veeva){
        setAnimate(true);
      }

  }, []);

  return ( 
    <TemplateWrapper>
      <div className={animate ? "" : "overwrite_animations"}>
      <div>
          <SEO title="Home | UK Residents | Non-HCP" />
          <Header queryData={queryData} animate={animate} />
          <NonukResidentsBanner queryData={queryData} animate={animate} />
          <Bio queryData={queryData} animate={animate} />
          <Vision queryData={queryData} animate={animate} />
          <UkNonHCPFooter queryData={queryData} animate={animate} />
      </div>
      </div>
    </TemplateWrapper>
  )
} 

export default HomeTemplatePageUkNonHcp