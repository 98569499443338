import React from 'react';
import { Link } from "gatsby"
import styles from "./changeSite.module.css"
class ChangeSiteVersion extends React.Component {
    constructor(props){
        super(props)
        this.state = {
          isActive : false,
        
        }
      }

      toggleClass = () => this.setState({
        isActive : !this.state.isActive
      })
    render() { 
        return ( 
            <div className={`${styles.change_theme} ${this.state.isActive ? `${styles.active}` : ""}`} >
            
            <div title="click here to change to other versions " className={styles.arrow} onClick={() => this.toggleClass()}><span>‹</span></div>
          <div className={styles.change_theme_content}>
         
          <ul>
              <li>Landing pages
              <ul>
                 <li><Link to="/">Landing 1</Link></li>
                
              </ul>
              </li>
              <li>Home pages
              <ul>
                 <li><Link to="/homePage2">Home 2</Link></li>
              </ul>
              </li>
             
          </ul>
          </div>
      </div>
         );
    }
}
 
export default ChangeSiteVersion;